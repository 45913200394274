@import "../../variables";

.TravelTile {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  height: 100%;

  &-imageWrapper {
    width: 100%;
    overflow: hidden;
    flex-grow: 0;
    display: flex;
    height: 200px;

    @include sm {
      height: 400px;
    }

    img {
      object-fit: cover;
    }
  }

  &-wrapper {
    background-color: #ebf5f2;
    padding: 10px;
    flex-grow: 1;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: flex-start;

    .TravelTile-main {
      background-color: #ffffff;
      padding: 20px 20px 15px 20px;
      flex-grow: 1;
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .TravelTile-content {
        flex-grow: 1;
        flex: 1;
        height: 100%;
        margin: 0;
      }

      .TravelTile-priceTag {
        justify-self: flex-end;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-direction: column;

        a {
          width: 100%;
          margin-top: 20px;

          button {
            width: 100%;
          }
        }

        @include sm {
          flex-direction: row;

          a {
            width: auto;
            margin-top: 0;

            button {
              width: auto;
            }
          }
        }
      }
    }
  }
}
