@import "../../variables";

.Home {
  .crossLinkBox {
    margin-bottom: 70px;

    @include sm {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
  
  .swiper-container {
    overflow: inherit;
  }
}
