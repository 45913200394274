@import "../../variables";

.footer {
  margin-top: 50px;
  font-family: $fontDefault;

  & > div {
    background: $secondaryColor;
    padding: 20px 0;
  }

  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 18px;

    @include md {
      flex-direction: row;
    }
  }

  .footerText {
    text-align: center;

    @include sm {
      text-align: right;
    }
  }

  .logo {
    display: flex;
    align-items: center;

    .mehrwerkHint {
      display: flex;
      flex-direction: column;
      padding: 3px 0 10px 10px;
      border-left: 1px solid #ffffff;

      span {
        color: #ffffff;
        font-size: 12px;
        margin-bottom: 0;
      }

      img {
        height: 25px;
      }
    }

    a {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1.1;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.1px;
      margin: 25px 12px;

      img {
        width: 80px;
        height: 80px;
        margin-left: 12px;
      }
    }
  }

  nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 15px;

    @include md {
      display: block;
    }

    a {
      margin: 0;

      &:after {
        content: "|";
        margin: 10px;
        color: #fff;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  a {
    color: #fff;
    margin: 10px;
    text-decoration: none;
  }

  .hideOnMobile {
    display: none;

    @include sm {
      display: inline;
    }
  }
}
