@import "./variables";

@font-face {
  font-family: "loraBold";
  src: url(/assets/fonts/Lora-Bold.ttf);
  font-weight: 400;
  font-style: bold;
}

@font-face {
  font-family: "palanquinRegular";
  src: url(/assets/fonts/Palanquin-Regular.ttf);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "palanquinBold";
  src: url(/assets/fonts/Palanquin-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

html {
  font-size: 87.5%;
}

body {
  font-size: $fontSizeDefault;
  line-height: 2rem;
  color: $textColor;
  font-family: "Arial", sans-serif;
}

.MuiButton-root .MuiButton-label {
  font-size: 1rem;
}

h1,
h2,
h3,
h4 {
  letter-spacing: 0;
  font-family: $fontSpecial;

  span {
    font-family: $fontSpecial;
  }
}

h1 {
  line-height: 1.1;
  font-weight: 600;
  color: $textColor;
}

h2 {
  line-height: 1.5;
  font-weight: 600;
  margin: 0.5em 0;
}

h3 {
  font-weight: 600;
}

h4 {
  font-weight: 600;
}

[class*="Tile"] {
  h2 {
    font-weight: 300;
    margin: 0;
  }
}

.font-extra-small {
  font-size: $fontSizeExtraSmall;
}

.font-small {
  font-size: $fontSizeSmall;
}

a.moreLink {
  font-size: 15px;
  font-family: $fontSpecial;

  @include sm {
    font-size: 18px;
  }
}

.Box {
  ol {
    list-style: none;
    counter-reset: list-counter;

    li {
      margin-bottom: 10px;
    }

    li::before {
      content: counter(list-counter);
      counter-increment: list-counter;
      display: inline-block;
      background: $secondaryColor;
      padding: 0;
      font-family: $fontSpecial;
      color: #ffffff;
      width: 25px;
      margin-left: -33px;
      margin-right: 8px;
      text-align: center;
      direction: rtl;
      border-radius: 3px;
      height: 25px;
      line-height: 25px;
    }
  }
}

a.twoColorLink,
a.twoColorLink:visited,
a.twoColorLink:focus,
a.twoColorLink:active,
a.twoColorLink:hover {
  color: $headlineColor;
  font-family: $fontDefaultBold;
  font-weight: 700;
}
