@import '../../variables';

.Notification {
  padding: 10px 10px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  font-family: 'mlp-ot-caps', sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.success {
    background-color: $successColorLighter;
    border-color: $successColorDarker;
    color: $successColorDarker;
  }

  &.warning {
    background-color: $warningColorLighter;
    border-color: $warningColorDarker;
    color: $warningColorDarker;
  }

  &.error {
    background-color: $errorColorLighter;
    border-color: $errorColorDarker;
    color: $errorColorDarker;
  }

  .icon {
    margin-right: 10px;
  }

  & > span {
    line-height: 1;

    .title,
    .text {
      width: 100%;
      display: inline-block;
      font-size: 12px;
      line-height: 1.2;
    }

    .text {
      font-family: 'mlp-ot', sans-serif;
    }
  }
}
