@import "../../variables";

.Box {
  padding: $boxPadding;
  background-color: #ffffff;

  &.colorful {
    background-color: #e6e9eb;
  }
}
