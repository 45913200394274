@import "../../variables";

.Tickets {
  .loginRequired {
    width: 80%;
    padding: 40px 30px;
    background: $secondaryColor;
    color: #ffffff;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include sm {
      width: 50%;
      padding: 2em;
    }

    .ticketHint {
      line-height: 1.5;
      font-size: 13px;

      @include xs {
        font-size: 15px;
      }
    }
  }

  .loginOverlay {
    position: absolute;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 10px;

    @include sm {
      top: 100px;
    }
  }
}
