@import "../../variables";

.Pagination {
  padding-top: 3em;
  text-align: center;

  span {
    margin: 0 2px;
    font-size: $fontSizeDefault;
    color: $textColor;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    font-family: $fontDefault;
    display: inline-block;

    &.active {
      background: $secondaryColor;
      color: #fff;
      border-radius: 5px;
    }

    &:hover {
      opacity: 0.5;
    }

    svg {
      position: relative;
      color: $primaryColor;
    }

    &.noHover {
      cursor: default;

      &:hover {
        opacity: 1;
      }
    }
  }
}
