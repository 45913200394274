@import "../../variables";

h1,
h2,
h3,
h4,
h5,
h6 {
  &.Headline {
    color: $headlineColor;
    flex-grow: 1;
    font-family: $fontSpecial;
    margin-top: 0;

    span {
      font-family: $fontSpecial;
    }

    p {
      margin: 0;
    }

    a,
    a:visited,
    a:focus,
    a:active,
    a:hover {
      color: $headlineColor;
      font-family: $fontSpecial;
    }

    &.fontRegular {
      font-family: $fontDefault;

      span {
        font-family: $fontDefault;
      }

      a,
      a:visited,
      a:focus,
      a:active,
      a:hover {
        font-family: $fontDefault;
      }
    }

    &.primary {
      color: $primaryColor;

      a,
      a:visited,
      a:focus,
      a:active,
      a:hover {
        color: $primaryColor;
      }
    }

    &.secondary {
      color: $secondaryColor;

      a,
      a:visited,
      a:focus,
      a:active,
      a:hover {
        color: $secondaryColor;
      }
    }

    &.text {
      color: $textColor;

      a,
      a:visited,
      a:focus,
      a:active,
      a:hover {
        color: $textColor;
      }
    }

    &.white {
      color: #ffffff;

      a,
      a:visited,
      a:focus,
      a:active,
      a:hover {
        color: #ffffff;
      }
    }
  }
}

h1.Headline {
  font-size: $fontSizeHeadline2;

  &.small {
    font-size: $fontSizeHeadline3;
  }

  @include sm {
    font-size: $fontSizeHeadline1;

    &.small {
      font-size: $fontSizeHeadline2;
    }
  }
}

h2.Headline {
  font-size: $fontSizeHeadline3;

  &.small {
    font-size: $fontSizeHeadline4;
  }

  @include sm {
    font-size: $fontSizeHeadline2;

    &.small {
      font-size: $fontSizeHeadline3;
    }
  }
}

h3.Headline {
  font-size: $fontSizeHeadline4;

  &.keepOnMobile {
    font-size: $fontSizeHeadline3;
  }

  @include sm {
    font-size: $fontSizeHeadline3;

    &.small {
      font-size: $fontSizeHeadline4;
    }
  }
}

h4.Headline {
  font-size: $fontSizeHeadline4;
}
