@import "../../variables";

.ImageSlider {
  margin-bottom: 30px;
  background: transparent;

  &.hasMoreImages {
    @media (max-width: #{$breakpoint-md}) {
      background: #ebf5f2;
      padding-bottom: 20px !important;
    }
  }

  .slide {
    width: 100%;
    padding-top: 30%;
    background-position: center center;
    background-size: cover;
    position: relative;
  }

  .swiper-pagination-bullet-active {
    background: $secondaryColor !important;
  }

  .swiper-pagination-bullet {
    background-color: #eee;
    opacity: 0.9;
    border: 1px solid #eee;
  }
  .content-box-stoerer {
    position: absolute;
    top: 100%;
    transform: translateY(-50%);
    right: 5%;
    padding: 24px;
    max-width: 40%;

    .stoerer-class {
      background-color: #495a70;
      width: 140px;
      height: 140px;
      border: 1px solid #495a70;
      text-align: center;
      border-radius: 100px;
      transform: rotate(20deg);
      cursor: pointer;
      line-height: 22px;
      
      & a {
        color: $borderColor;
        display: flex;
        border-radius: 100px;
        margin-top: 46px;
        margin-bottom: 28px;
        font-size: 20px;
      }

      .highlighted {
        text-decoration: underline;
      }
    }
  }
  .content-box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5%;
    background: #ffffffee;
    padding: 24px;
    max-width: 40%;

    h4 {
      margin: 0;
      font-weight: 600;
      color: $secondaryColor;
    }

    h2 {
      margin-top: 0;
    }

    p {
      font-family: $fontDefault;
    }
  }

  @media (max-width: #{$breakpoint-md}) {
    .slide {
      padding-top: 50%;
    }

    .content-box {
      position: static;
      transform: none;
      top: 0;
      max-width: 100%;
      background: #ebf5f2;
    }
  }
}
