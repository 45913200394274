@import "../../variables";

.Travel {
  .loginRequired {
    width: 80%;
    padding: 40px 30px;
    background: $secondaryColor;
    color: #ffffff;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include sm {
      width: 50%;
      padding: 2em;
    }
  }
}
