@import "../../variables";

.ServiceTileWrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 30px;

  @include sm {
    margin-top: 0;
  }

  .ServiceTile {
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    margin-bottom: 30px;
    align-items: flex-end;

    @include sm {
      flex-direction: row;
      align-items: center;
    }

    .TimePeriods {
      margin-right: 0;
      text-align: right;

      @include sm {
        margin-right: 30px;
        text-align: left;
      }
    }

    a.tel,
    a.tel:visited,
    a.tel:focus,
    a.tel:active,
    a.tel:hover {
      color: $secondaryColor;
      font-size: 1.7rem;
      line-height: 1.5;
      font-weight: 600;
      margin: 0.5em 0 -10px;
      max-width: 80%;
      font-family: $fontDefault;

      @include md {
        font-size: $fontSizeHeadline2;
      }
    }

    .mehrwerkHint {
      text-align: right;
      display: inline-block;
      width: 100%;
      font-size: 13px;
    }
  }
}
