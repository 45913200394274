$breakpoint-xs: "0px";
$breakpoint-sm: "600px";
$breakpoint-md: "1140px";
$breakpoint-lg: "1280px";
$breakpoint-xl: "1920px";

@mixin xs {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$breakpoint-xl}) {
    @content;
  }
}

$primaryColor: #f15c22;
$secondaryColor: #0c2340;
$textColor: #495a70;
$headlineColor: #333333;
$lightTextColor: #737373;
$highlightColor: #009fd6;
$borderColor: #f0f0f0;
$lightGreenBackground: #e6e9eb;

$errorColorLight: #f4b0a9;
$errorColorLighter: #fdf1f0;
$errorColorDarker: #c15b52;
$warningColorLight: #faec91;
$warningColorLighter: #fdf8da;
$warningColorDarker: #c4af2b;
$successColorLighter: #b9ddb4;
$successColorLight: #91cb8a;
$successColorDarker: #77a771;

$starColor: #fcc200;

$fontDefault: "palanquinRegular", sans-serif;
$fontDefaultBold: "palanquinRegularBold", sans-serif;
$fontSpecial: "loraBold", serif;

$fontSizeDefault: 1.15rem;
$fontSizeSmall: 0.857rem;
$fontSizeExtraSmall: 0.714rem;

$fontSizeHeadline1: 2.9rem; // 40px
$fontSizeHeadline2: 1.8rem; // 25px
$fontSizeHeadline3: 1.15rem; // 16px
$fontSizeHeadline4: 0.86rem; // 12px

$boxPadding: 30px 30px;
